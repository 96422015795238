import React from 'react';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import Image from '../../../components/Image';
import Subfooter from '../../../components/Subfooter';
import Hero from '../../../components/Hero';
import CourseUnitCard from '../../../components/CourseUnitCard';

const heroMultiImages = [
  {
    image: '/img-lead-biomeds.jpg',
    title: 'Biomed',
    subtext: 'CTE Curriculum | 9-Week Units',
    text:
      'Explore the next generation of innovative and equitable medicine being made possible by biotechnology breakthroughs, from data-based diagnosis to wearable medical devices.',
  },
  {
    image: '/img-lead-biomed-top-right.jpg',
  },
  {
    image: '/img-lead-biomed-bottom-right.jpg',
  },
];

const IndexPage = () => (
  <Layout title="Biomed">
    <Hero className="pb-0" mediaSource={heroMultiImages} color={'orange'} />
    <Section className="living-earth__at-a-glance-section pt-0">
      <Container fullWidth className="living-earth__at-a-glance-container">
        <Row>
          <Column size={12}>
            <h5 className="mb-0">BIOMED COURSE</h5>
            <h3 className="living-earth__at-a-glance-header">At a Glance</h3>
          </Column>
        </Row>
        <Row>
          <Column medium={3} xsmall={6}>
            <Image filename="icon-instructional-support.svg" />
            <h4 className="living-earth__at-a-glance-section-header mt-0">
              EDUCATOR PREP
            </h4>
            <p className="mr-3">
              Each unit includes pacing guide, standards alignment,
              framing/instructional support and lab material needs.
            </p>
          </Column>
          <Column medium={3} xsmall={6}>
            <Image filename="icon-course-outline.svg" />
            <h4 className="living-earth__at-a-glance-section-header mt-0">
              COURSE OUTLINE
            </h4>
            <ul className="living-earth__at-a-glance-li">
              <li>1 School Year</li>
              <li>9 Week Units</li>
              <li>Quarter-based Units</li>
            </ul>
          </Column>
          <Column medium={3} xsmall={6}>
            <Image filename="icon-topics-covered.svg" />
            <h4 className="living-earth__at-a-glance-section-header mt-0">
              TOPICS COVERED
            </h4>
            <ul className="living-earth__at-a-glance-li">
              <li>Cell Biology</li>
              <li>Mitosis &amp; Meiosis</li>
              <li>Aging</li>
              <li>Medical Devices</li>
            </ul>
          </Column>
          <Column medium={3} xsmall={6}>
            <Image filename="icon-student-outcomes.svg" />
            <h4 className="living-earth__at-a-glance-section-header mt-0">
              STUDENT OUTCOMES
            </h4>
            <ul className="living-earth__at-a-glance-li">
              <li>CTE Aligned Learning</li>
              <li>Lab Skills</li>
              <li>Project Based Experiences</li>
            </ul>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pb-0">
      <Container fullWidth>
        <Row className="pb-1">
          <Column size={8}>
            <h3 className="living-earth__getting-started-header mb-0.5">
              Getting Started
            </h3>
            <p className="living-earth__getting-started-desc">
              The units below are designed to be used sequentially over the
              course of the year with supporting lessons, labs and student
              activities. Begin by exploring each unit to gain additional
              details related to each quarter of the year.
            </p>
          </Column>
        </Row>
        <CourseUnitCard
          unitIdentifier="Unit 1"
          header="Crowdsourcing Innovations in Biotech"
          subheader="9 Weeks | Lesson Plans, Labs, Interactives and more"
          description="<strong>How can data help us prevent and control diseases?</strong> 
                As scientific knowledge evolves, so is the technology that helps us solve problems. 
                Discover why the wearable tech industry has taken off thanks to its unique potential 
                for monitoring health."
          img="thmb-biomed-unit1@2x.jpg"
          to="/classroom-instruction/biomed/unit-1"
        />
        <CourseUnitCard
          unitIdentifier="Unit 2"
          header="Taking Action in Your Community: Health Equity"
          subheader="9 Weeks | Lesson Plans, Labs, Interactives and more"
          description="<strong>How can biotechnology be successfully incorporated into a community?</strong> 
                Take on the role of a team member in a biotechnology firm that works to mitigate pandemics 
                by developing vaccines or creating short/long term responses to pandemics."
          img="thmb-biomed-unit2@2x.jpg"
          to="/classroom-instruction/biomed/unit-2"
        />
        <CourseUnitCard
          unitIdentifier="Unit 3"
          header="Nucleic Acids and Proteins: Disease Treatment Innovations"
          subheader="11 Weeks | Lesson Plans, Labs, Interactives and more"
          description="<strong>How can biotechnology help us develop and deliver pharmaceutical drugs?</strong> 
                Explore the ways medical devices are designed to reduce pain when administering medications 
                and to increase comfort for patients."
          img="thmb-biomed-unit3@2x.jpg"
          to="/classroom-instruction/biomed/unit-3"
        />
        <CourseUnitCard
          unitIdentifier="Unit 4"
          header="Behind the Scenes of Scientific Breakthroughs"
          subheader="11 Weeks | Lesson Plans, Labs, Interactives and more"
          description="<strong>How can we use biotechnology to increase longevity and improve the quality of life for 
                an aging population, while possibly extending the lifespan of humans?</strong> 
                Consider how our society can improve the quality of life for our aging population."
          img="thmb-biomed-unit4@2x.jpg"
          to="/classroom-instruction/biomed/unit-4"
        />
      </Container>
    </Section>
    <Section className="pb-0">
      <Subfooter content="Biotech is revolutionizing every aspect of life, from advances in medical diagnostics and drug development to improvements in agricultural and food products." />
    </Section>
  </Layout>
);

export default IndexPage;
